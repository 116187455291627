import React from "react";

const ListGroup = ({
  items,
  textProperty,
  primaryKeyProperty,
  selectedItem,
  onItemSelect
}) => {
  return (
    <ul className="list-group">
      {items.map(item => {
        return (
          <li
          onClick={() => onItemSelect(item)}
          key={item[primaryKeyProperty]}
          className={
            selectedItem && (item[primaryKeyProperty] === selectedItem[primaryKeyProperty]) ? "list-group-item active" : "list-group-item"
          }
          > 
            {item[textProperty]}
          </li>
        )
        
})}
    </ul>
  );
};

ListGroup.defaultProps = {
  textProperty: "name",
  primaryKeyProperty: "id"
};

export default ListGroup;

import elena from '../../../content/img/thanks.jpg'

const Thanks = () => {
    return ( 
        <div className="fullscreen dark">
            <div className="thankyounote">
                <h3>Bedankt!</h3>
                <img src={elena} alt='Elena zegt dank u!'/>
            </div>

        </div>
     );
}
 
export default Thanks
// Fields:
// path: string representing the path (e.g. '/')
// element: react element to show in the body of the app
// label: string or icon of the button in the navbar (remove if no navbutton needs to be generated)
// icon: an icon that can be displayed
// disabled: boolean to disable the button. It is still visible but greyed-out
// children: children routes

import AddCouple from "../components/routes/subroutes/addcouple";
import CalculationForm from "../components/routes/subroutes/calculationform";
import Contacts from "../components/routes/contacts";
import CoupleTable from "../components/routes/subroutes/coupleTable";
import CoupleDetail from "../components/routes/subroutes/coupledetail";
import ErrorPage from "../components/routes/errorpage";
import Home from "../components/routes/home";
import Rsvp from "../components/routes/subroutes/rsvpform";
import SaveTheDate from "../components/routes/savethedate";
import Thanks from "../components/routes/subroutes/thankyou";
import { getCoupleByParamId, getCouples } from "../services/apiservice";
import PracticalInfo from "../components/routes/practicalInfo";
import Playlist from "../components/routes/playlist";
import Planning from "../components/routes/planning";
import PresentTable from "../components/routes/subroutes/presentTable";

export const appRoutes = 
    [
        { 
            path: "/", 
            id: "root",
            label: "Home",
            element: <Home />
        },
        {
            path: "praktisch", 
            label: "Praktische Info",
            element: <PracticalInfo />
            
        },
        { 
            path: "planning", 
            label: "Programma",
            element: <Planning />
        },
        { 
            path: "savethedate", 
            element: <SaveTheDate />,
            id: "rsvp",
            errorElement: <ErrorPage/>,
            children: [
                { 
                    path: "thanks", 
                    element: <Thanks />
                },
                { 
                    path: ":id", 
                    element: <Rsvp />,
                    loader: getCoupleByParamId
                },
            ]
        },
        { 
            path: "koppels", 
            element: <Contacts />,
            errorElement: <ErrorPage/>,
            id: "couples",
            loader: getCouples,
            children:[
                { 
                    path: "tabel", 
                    element: <CoupleTable />,
                    label: "Tabel"
                },
                { 
                    path: "aanwezigen", 
                    element: <PresentTable />,
                    label: "Aanwezigen"
                },
                {
                    path: "rekenblad", 
                    element: <CalculationForm />,
                    label: "Rekenblad"
                },
                { 
                    path: "add", 
                    element: <AddCouple />
                },
                { 
                    path: ":id",
                    element: <CoupleDetail />
                }
            ]
        },
        
        
    ]
import { Outlet } from 'react-router-dom';
import ringPicture from '../../content/img/ring.jpg'
import TitleBanner from '../common/titlebanner';
import React from 'react';

const SaveTheDate = () => {

    return ( 
        <React.Fragment>
            <section className="fullscreen photo withnav">
                <TitleBanner/>
            </section>
            <section className="fullscreen cover">
                <div className="verhaal">
                    <h3>Viva España</h3>
                    <p>Lieve vrienden en familie, <br /><br />

                        Wat een avontuur! <br />
                        Zes jaar geleden sloeg de vonk over. <br />
                        In die zes jaar hebben we samen al vele mooie momenten gehad, leuke reizen gemaakt, een thuis gevonden in 'huisje Opwijk' en drie prachtige kinderen, Elena, Maya en Lex, gekregen.
                        En nu is het tijd voor een volgend hoofdstuk.
                        We stappen in het huwelijksbootje! <br /><br />

                        Al lang dromen we van een outdoor huwelijksfeest onder de Spaanse zon. <br />
                        We hebben besloten om hiervoor te gaan! <br /><br />

                        Wanneer? 6 juli 2024 <br />
                        Waar? Bolnuevo/Morata (Murcia), Spanje <br /><br />

                        We zouden het super fijn vinden moesten jullie mee kunnen komen om te klinken op ons huwelijk! <br />
                        De officiële uitnodiging en praktische details volgen later nog, maar bij deze alvast een 'Save The Date' <br />
                        Kunnen jullie voor 10 september laten weten of dit al dan niet in jullie vakantieplanning past? <br /> <br />

                        Wij kijken er alvast naar uit! <br /><br />

                        Vele en lieve groetjes,<br /><br />
                        
                        Joyce & Yelle
                    </p>
                    <img src={ringPicture} alt={"Huwelijk Joyce & Yelle"} className="foto"/>
                </div>
            </section>
            <Outlet />
        </React.Fragment>
     );
}
 
export default SaveTheDate;


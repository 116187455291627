import { NavLink, useLocation } from 'react-router-dom';

// Inputs:
// currentpath: string > the current routepath 
// routes: Approutes > the list of routes of this application

const Navbar = ({ routes, title }) => {

    const RenderNavButton = (item) => {
        const { path, disabled, label, icon } = item

        var classes = "link"
        if (disabled) classes += " disabled";
        if (useLocation().pathname === path) classes += " active"
        
        return <NavLink key={path} className={classes} to={path}><span className="p-2">{icon}</span>{label}</NavLink>
    }
    return (
        <nav className="navbar">
            <span className='brand'>{title}</span>
            <div className="linkcontainer">
                { routes.map(item => RenderNavButton(item)) }
            </div>
        </nav>
    );
}
 
export default Navbar;
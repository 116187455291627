import React from "react";

const Planning = () => {
  return (
    <React.Fragment>
      <section className="photo">
        <div className="infopage">
          <div className="introSection">
            <span>
              Lieve gasten, <br />
              <br />
              Super fijn dat jullie erbij kunnen zijn! Hier is het programma
              voor ons feestelijk weekend: <br />
              <br />
            </span>
          </div>

          <div className="planning">
            <div className="event">
              <div className="textSection">
                <span className="title">Kick-off wedding weekend</span>
                <span className="description">
                  Welkom in Casa Jabeque in Bolnuevo voor een hapje en een
                  drankje! Een informele avond om elkaar allemaal te leren
                  kennen. <br />
                  Geniet van het uitzicht, neem een plonsje in het zwembad en
                  laat de festiviteiten beginnen! <br />
                </span>
              </div>
              <div className="info">
                <div className="infoVeld">
                  <span className="label">Wanneer</span>
                  <span className="value">
                    Vrijdag 05/07/2024 - Van 19:00 tot 23:00
                  </span>
                </div>
                <div className="infoVeld">
                  <span className="label">Waar</span>
                  <span className="value">Travesia Jabeque 2, Bolnuevo</span>
                </div>
                <div className="infoVeld">
                  <span className="label">Dress Code</span>
                  <span className="value">
                    Summer chill <br /> Voor diegene die een plonske willen
                    doen, zwemgerief niet vergeten!
                  </span>
                </div>
              </div>
            </div>
            <div className="event">
              <div className="textSection">
                <span className="title">‘I do’ on the beach</span>
                <span className="description">
                  Hello summer vibes! We verzamelen op het strand voor een korte
                  ceremonie en heffen samen het glas. Voor de vakantiegangers
                  onder ons – nadien chillen op het strand en afkoelen in de zee
                  is zeker een mogelijkheid! De juiste locatie wordt een paar
                  dagen voordien meegegeven, nog een beetje mysterie ;-) <br />
                </span>
              </div>
              <div className="info">
                <div className="infoVeld">
                  <span className="label">Wanneer</span>
                  <span className="value">
                    Zaterdag 06/07/2024 - 10:00 - 12:00
                  </span>
                </div>
                <div className="infoVeld">
                  <span className="label">Waar</span>
                  <span className="value">TBD (In de buurt van Bolnuevo)</span>
                </div>
                <div className="infoVeld">
                  <span className="label">Dress Code</span>
                  <span className="value">
                    Summer chill <br /> Zonnecreme en zwemgerief niet vergeten
                    voor de vakantiegangers
                  </span>
                </div>
              </div>
            </div>
            <div className="event">
              <div className="textSection">
                <span className="title">Tapas & dansen in de bergen</span>
                <span className="description">
                  Tijd om de zee achter ons te laten en te verhuizen naar de
                  bergen. Geen traditionele 3-gangenmenu aan tafel, maar walking
                  dinner en spaanse tapas! Al dansend sluiten we ons weekend af,
                  buiten en hopelijk onder een mooie sterrenhemel. <br />
                </span>
              </div>
              <div className="info">
                <div className="infoVeld">
                  <span className="label">Wanneer</span>
                  <span className="value">
                    Zaterdag 06/07/2024 - 19:00 - 03:00
                  </span>
                </div>
                <div className="infoVeld">
                  <span className="label">Waar</span>
                  <a className="value" href="">
                    La Finca Fraille in Morata
                  </a>
                </div>
                <div className="infoVeld">
                  <span className="label">Dress Code</span>
                  <span className="value">
                    Summer chic <br /> Avondfeest (inclusief dansen) is buiten
                    te doen, met kiezeltjes als ondergrond. De bruid doet een
                    eerste poging op hakken, maar zal al snel omschakelen naar
                    gemakkelijkere dansschoenen.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Planning;

import { useNavigate } from "react-router-dom"
import { getGroups, updateItemByRef, addItem } from "../../services/apiservice"
import { refEqual, updateDoc } from "firebase/firestore/lite"
import { useEffect } from "react"
import { useState } from "react"

const CoupleForm = ({ coupleItem, newCouple }) => {
    const navigate = useNavigate()
    const [groups, setGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [name, setName] = useState(coupleItem.naam)
    const [category, setCategory] = useState(coupleItem.categorie)
    const [email, setEmail] = useState(coupleItem.email)
    const [tel, setTel] = useState(coupleItem.tel)
    const [acceptChildren, setAcceptChildren] = useState(coupleItem.accepteerkinderen)
    const [gestuurd, setGestuurd] = useState(coupleItem.gestuurd? coupleItem.gestuurd: false)
    const [acknowledged, setAcknowledged] = useState(coupleItem.confirmaties.savethedate)
    const [present, setPresent] = useState(coupleItem.aanwezig)
    const [people, setPeople] = useState([])
    const [remarks, setRemarks] = useState(coupleItem.opmerkingen)

    useEffect(() => {
        getGroups().then(groups => { 
            setGroups(groups); 
            if (!newCouple) setSelectedGroup(groups.find(group => refEqual(coupleItem.groep, group.ref)).doc.naam)
        })
        let currentId = 0
        const volwassenen = coupleItem.volwassenen.map(person => {
            currentId += 1
            return {...person, categorie: "volwassene", id: currentId}
        })
        const kinderen = coupleItem.kinderen.map(person => {
            currentId += 1
            return {...person, categorie: "kind", id: currentId}
        })
        setPeople([...volwassenen, ...kinderen].sort((a,b) => {return a.id - b.id}))
    },[])

    const deletePerson = (personId) => {
        const newlist = people.filter(item => item.id != personId)
        setPeople(newlist)
    }

    const addNew = () => {
        let newId = 1
        if (people.length != 0) newId = Math.max(...people.map(person => person.id)) + 1
        const newlist = [...people, {categorie: "volwassene", naam:"", aanwezig: false, id: newId}]
        setPeople(newlist)
    }

    const cancelDefault = (e) => {
        e.preventDefault()
    }

    const submitForm = () => {
        console.log(coupleItem)
        const newObject = {
            naam: name,
            aanwezig: present,
            accepteerkinderen: acceptChildren,
            categorie: category,
            gestuurd: gestuurd,
            confirmaties: {
                savethedate: acknowledged? true: false,
                events: false
            },
            email: email? email: "",
            tel: tel? tel: "",
            groep: groups.find(group => group.doc.naam === selectedGroup).ref,
            opmerkingen: remarks? remarks: "",
            volwassenen: people.filter(person => person.categorie === "volwassene").map(item => {return {naam: item.naam, aanwezig: item.aanwezig}}),
            kinderen: people.filter(person => person.categorie === "kind").map(item => {return {naam: item.naam, aanwezig: item.aanwezig}})
        }
        if (newCouple) addItem('koppels', newObject)
        else updateItemByRef(coupleItem.ref, newObject)
        navigate("/koppels/tabel")
    }

    const updatePerson = (id, changeobject) => {
        const updatedPerson = {...people.find(person => person.id === id), ...changeobject}
        const newList = people.filter(person => person.id !== id)
        newList.push(updatedPerson)
        setPeople(newList.sort((a,b) => {return a.id - b.id}))
    }

    return (  
        <div className="dark">
            <div className="commandbar"><button onClick={() => navigate("/koppels/tabel")} className="btn btn-light">Terug</button></div>
            <form  className="rsvpform" onSubmit={cancelDefault}>
                    <div className="mb-3">
                        <label htmlFor="inputName" className="form-label">Naam</label>
                        <input type="text" className="form-control" id="inputName" name="couple" onChange={(e) => setName(e.target.value)} defaultValue={name}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputName" className="form-label">Groep</label>
                        <select className="form-select" aria-label="Default select example" onChange={(e) => setSelectedGroup(e.target.value)} value={selectedGroup? selectedGroup: ""}>
                            <option key={0} value=""></option>
                            {groups.map(group => 
                                <option key={group.id} value={group.doc.naam}>{group.doc.naam}</option>
                                )}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputName" className="form-label">Categorie</label>
                        <select className="form-select" aria-label="Default select example" onChange={(e) => setCategory(e.target.value)} value={category? category: ""}>
                            <option value="Must have">Must have</option>
                            <option value="Nice to have">Nice to have</option>
                            <option value="Obligated">Obligated</option>
                        </select>
                    </div>
                    {newCouple? null: 
                        <div className="mb-3">
                            <label htmlFor="inputName" className="form-label">Link</label>
                            <input type="text" readOnly className="form-control" id="inputlink" name="link" defaultValue={"www.jnyforever.be/savethedate/"+ coupleItem.id}/>
                        </div>
                    }
                    <div className="mb-3">
                        <label htmlFor="inputEmail" className="form-label">E-mail</label>
                        <input type="email" className="form-control" id="inputEmail" name="email" onChange={(e) => setEmail(e.target.value)} defaultValue={email}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputEmail" className="form-label">Telefoon</label>
                        <input type="tel" className="form-control" id="inputEmail" name="tel" onChange={(e) => setTel(e.target.value)} defaultValue={tel}/>
                    </div>
                    {newCouple? null: 
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" readOnly role="switch" name="gestuurd" onChange={(e) => setGestuurd(e.target.checked)} defaultChecked={gestuurd}/>
                            <label className="form-check-label" htmlFor="checkAccepteerKinderen">Gestuurd</label>
                        </div>
                    }
                    {!gestuurd? null: 
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" readOnly role="switch" name="bevestigd" onChange={(e) => setAcknowledged(e.target.checked)} defaultChecked={acknowledged}/>
                            <label className="form-check-label" htmlFor="checkAccepteerKinderen">Bevestigd</label>
                        </div>
                    }
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" name="accepteerKinderen" onChange={(e) => setAcceptChildren(e.target.checked)} defaultChecked={acceptChildren}/>
                        <label className="form-check-label" htmlFor="checkAccepteerKinderen">Accepteer kinderen</label>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" name="aanwezig" onChange={(e) => setPresent(e.target.checked)} defaultChecked={present} id="checkAanwezig"/>
                        <label className="form-check-label" htmlFor="checkAanwezig">Aanwezig</label>
                    </div>
                    <div className="my-5">
                            <label htmlFor="inputName" className="form-label">Genodigden:</label>
                            <button onClick={addNew} className="btn btn-primary">Nieuw</button>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Categorie</th>
                                        <th scope="col">Naam</th>
                                        <th scope="col">Aanwezig</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {people.map(person => (
                                        <tr key={person.id}>
                                           <td>
                                                <select className="form-select" aria-label="Default select example" onChange={(e) => updatePerson(person.id, {categorie: e.target.value})} value={person.categorie? person.categorie: ""}>
                                                    <option value="volwassene">volwassene</option>
                                                    <option value="kind">kind</option>
                                                </select>
                                           </td>
                                            <td><input type="text" className="form-control" onChange={(e) => updatePerson(person.id, {naam: e.target.value})} defaultValue={person.naam}/></td>
                                            <td><input className="form-check-input" type="checkbox" role="switch" onChange={(e) => updatePerson(person.id, {aanwezig: e.target.checked})} defaultChecked={person.aanwezig}/></td>
                                            <td><button onClick={(e) => deletePerson(person.id)} className="btn btn-danger">Delete</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                    <div className="mb-3">
                        <label htmlFor="inputRemark" className="form-label">Opmerkingen</label>
                        <textarea className="form-control" id="inputRemark" name="remark" rows="3" onChange={(e) => setRemarks(e.target.value)} defaultValue={remarks}></textarea>
                    </div>
                    <button type="submit" onClick={submitForm} className="btn btn-primary">Opslaan</button>   
            </form>
        </div>
    )
}
 
export default CoupleForm;
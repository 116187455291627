import { redirect, useLoaderData, useParams, useRouteLoaderData } from "react-router-dom";
import { getCoupleById } from "../../../services/apiservice";
import { updateDoc } from "firebase/firestore/lite";
import CoupleForm from "../../common/couplefrm";

const CoupleDetail = () => {
    const list = useRouteLoaderData("couples")
    const { id: coupleId } = useParams('id')
    const { doc, id, ref } = list.find(item => item.id === coupleId)
    
    return (
        <div>
            <CoupleForm coupleItem={{...doc, id: id, ref: ref}} />
        </div>
    )
}
 
export default CoupleDetail;
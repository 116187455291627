import { useLoaderData, useNavigate } from "react-router-dom";
import { getCoupleById, updateItemByRef } from "../../../services/apiservice";
import { useState } from "react"


const Rsvp = () => {
    const navigate = useNavigate()
    const { doc, id } = useLoaderData()
    const [name, setName] = useState(doc.naam)
    const [email, setEmail] = useState(doc.email)
    const [present, setPresent] = useState(doc.aanwezig)
    const [adults, setAdults] = useState(doc.volwassenen)
    const [children, setChildren] = useState(doc.kinderen)
    const [remarks, setRemark] = useState(doc.opmerkingen)

    const togglePerson = (name) => {
        let index = adults.findIndex(person => person.naam === name)
        if(index >= 0) {
            adults[index].aanwezig = !adults[index].aanwezig
            setAdults(adults)
        }
        else {
            index = children.findIndex(person => person.naam === name)
            children[index].aanwezig = !children[index].aanwezig
            setChildren(children)
        }
        

    }

    const renderToggle = (person) => {
        return (
            <div className="form-check" key={"key" + person.naam}>
                <input key={"key" + person.naam} className="form-check-input" type="checkbox" role="switch"  id={"flexCheckDefault" + person.naam} onChange={(e) => togglePerson(person.naam)} defaultChecked={doc.confirmaties.savethedate && person.aanwezig}/>
                <label className="form-check-label" htmlFor={"flexCheckDefault" + person.naam}>
                    {person.naam}
                </label>
            </div>
        )
    }

    const cancelDefault = (e) => {
        e.preventDefault()
    }

    const submitForm = async () => {
        const rawCoupleData = await getCoupleById(id)
        const newObject = {
            naam: name,
            aanwezig: present,
            gestuurd: true,
            confirmaties: {
                savethedate: true,
                events: false
            },
            email: email? email: "",
            opmerkingen: remarks? remarks: "",
            volwassenen: adults,
            kinderen: children
        }

        updateItemByRef(rawCoupleData.ref, newObject)
        navigate("/savethedate/thanks")
    }

    return (  
        <div className="fullscreen dark">
            <form className="rsvpform" onSubmit={cancelDefault}>
                    <h3>Kom je met ons mee?</h3>
                    <div className="mb-3">
                        <label htmlFor="inputName" className="formlabel">Naam</label>
                        <input type="text" className="form-control" id="inputName" onChange={(e) => setName(e.target.value)} name="couple" defaultValue={name}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputEmail" className="formlabel">E-mail</label>
                        <input type="email" className="form-control" id="inputEmail" onChange={(e) => setEmail(e.target.value)} name="email" defaultValue={email}/>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input radio" type="radio" name="aanwezig" id="flexRadioDefault1" onChange={(e) => setPresent(true)} defaultChecked={doc.confirmaties.savethedate && present} value={true}/>
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            {[...doc.volwassenen, ...doc.accepteerkinderen? doc.kinderen:[]].length > 1? "Jaa! Wij komen!": "Jaa! Ik kom!"}
                        </label>
                    </div>
                    {[...adults, ...doc.accepteerkinderen? children:[]].length > 1?
                        <div className="container mb-3 m-3">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="inputName" className="formlabel">Aanwezig:</label>
                                {adults.map(person => renderToggle(person))}
                                {doc.accepteerkinderen? children.map(person => renderToggle(person)):null}
                            </div>
                        </div>
                    </div>: null
                    }
                    
                    <div className="form-check mb-3">
                        <input className="form-check-input radio" type="radio" name="aanwezig" onClick={(e) => setPresent(false)} defaultChecked={doc.confirmaties.savethedate && !present} id="flexRadioDefault2" value={false}/>
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                        {[...adults, ...doc.accepteerkinderen? children:[]].length > 1? "Jammer maar helaas, wij kunnen er niet bij zijn.": "Jammer maar helaas, ik kan er niet bij zijn."}
                        </label>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputRemark" className="formlabel">Nog vragen?</label>
                        <textarea className="form-control" id="inputRemark" name="remark" rows="3" onClick={(e) => setRemark(e.target.value)} defaultValue={remarks}></textarea>
                    </div>
                    <button onClick={submitForm} className="btn btn-primary">Versturen</button>   
            </form>
        </div>
    );
}
 
export default Rsvp;
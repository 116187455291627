import { Outlet } from "react-router-dom";
import { appRoutes } from "../../appdata/approutes";
import Navbar from "../common/navbar";

const Contacts = () => {
    return (
        <div>
            <Navbar routes={appRoutes.find(route => route.id === "couples").children} title="J & Y"/>
            <Outlet />
        </div>
        
    )
    
}
 
export default Contacts;
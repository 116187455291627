export default function ErrorPage() {
  return (
    <div className="container-fluis appbody text-center">
      <div className="row h-100 align-items-center">
        <div className="col">
          <h1>Oeps!</h1>
          <p>Er is iets foutgegaan! <br /> Controleer uw internetconnectie en probeer even opnieuw!</p>
        </div>
      </div>
    </div>
  );
}
import airplane from "../../content/img/travelwithchildren.jpg"
import car from "../../content/img/car.jpg"
import sleep from "../../content/img/hangmat.jpg"
import eat from "../../content/img/drinks.jpg"

const PracticalInfo = () => {
    return ( 
        <div className="photo">
            <div className="infopage">
                <h1>Praktische Info</h1>
                <div>
                    <h2>Luchthavens</h2>
                    <div className="rowWrap">
                        <img src={airplane} alt="" />
                        <p>
                            Er zijn twee mogelijke luchthavens in de buurt: <br /> <br />
                            Alicante <br />
                            Murcia <br />
                            <br />
                            De meeste vliegtuigmaatschappijen vliegen op Alicante, aangezien dit van de beiden de grootste luchthaven is.
                            Vanuit Alicante moet je een 1h25m rijden om in Bolnuevo aan te komen met de auto. <br />
                            <br />
                            De andere optie is de luchthaven van Murcia (Corvera). <br />
                            Het vliegaanbod van deze luchthaven is wel beperkter en volgens onze ervaring is deze iets duurder. <br />
                            Als je naar Murcia vliegt kun je niet vanuit Brussel vertrekken, wel vanuit Oostende of Antwerpen. <br />
                            Vanuit Murcia is het nog 43m rijden naar Bolnuevo. <br /> <br />
                            Ready for take-off!
                        </p>
                    </div>
                </div>
                <div>
                    <h2>Vervoer</h2>
                    <div className="rowWrap">
                        <p>
                        Geland! Dan rest nu de vraag: hoe bereik je het betoverende Bolnuevo? <br /> <br />
                        Vanuit Alicante kan je zeker de bus nemen tot in Bolnuevo (overstappen in Murcia). <br />
                        Een auto is echter geen overbodige luxe daar het tot het feest zelf ook nog een half uurtje rijden is. <br /> <br />
                        Op de luchthaven zijn verschillende verhuurbedrijven waar je een auto kan huren. Wij reserveren meestal onze auto via de site doyouspain.com.
                        </p>
                        <img src={car} alt="" />
                    </div>
                </div>
                <div>
                    <h2>Verblijf</h2>
                    <div className="rowWrap">
                        <img src={sleep} alt="" />
                            <p>
                            Er zijn verschillende opties ivm verblijf – alles hangt af van wat jullie juist willen. We zijn er zelf nog niet geweest, maar er zijn wel wat mogelijkheden in de buurt. <br />
                            <br />
                            <b>Hotels:</b> <br />
                            -	Hotel Playa Granda (Puerto de Mazarron / Bolnuevo): hotel aan het strand, een dik kwartiertje stappen naar ons huisje
                            <br /><a href="https://www.booking.com/hotel/es/playagrande.en-gb.html?aid=311984&label=playagrande-a3CLavjMLTFpPWFFatICLAS675506788040%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atikwd-30707507275%3Alp1001004%3Ali%3Adec%3Adm%3Appccp%3DUmFuZG9tSVYkc2RlIyh9YXwxhKG0pUU-mcMVT-JwQpc&sid=975115964c8a578d65133f2c6184318c&dest_id=-381096;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1701686690;srpvid=06154b8d17fd000b;type=total;ucfs=1&#hotelTmpl">Booking - Playa Grande</a> <br />
                            <br />
                            -	Hotel Atrium (Bolnuevo): hotel in de woonwijk, 5 minuten stappen tot aan het strand <br />
                            <a href="https://www.booking.com/hotel/es/atrium.nl.html">Booking - Hotel Atrium</a> <br />
                            <br />
                            <b>Appartementen:</b> <br />
                            -	Appartamentos Oasis de las Palmeras (Bolnuevo): vlak aan het strand – verschillende appartementen met zwembad en tennisbaan. Ook een bar en restaurant zijn aanwezig. Lijkt ons wel heel tof! <br />
                            <a href="https://www.booking.com/hotel/es/apartamentos-oasis-de-las-palmeras.es.html">Booking - Oasis de las Palmeras</a> <br />
                            <br />
                            <b>Huisjes:</b> <br />
                            Verschillende vakantie huizen te huur via oa Airbnb – zoeken op Bolnuevo, Puerto de Mazarron of Mazarron. <br />
                            <br />
                            Laat de vakantie beginnen!
                        </p>
                    </div>
                </div>
                <p>
                Hebben jullie meer vragen of gewoon zin in een babbeltje over het aankomende avontuur?<br />
                Aarzel niet om ons een berichtje te sturen. <br /><br />
                We kijken er naar uit om met jullie ons huwelijk te vieren! <br />
                <br /><br />
                Lieve groetjes, <br />
                Joyce & Yelle

                </p>
            </div>
        </div>
     );
}
 
export default PracticalInfo;
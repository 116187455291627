import { getGroups } from "../../../services/apiservice";
import { useRouteLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
import { refEqual } from "firebase/firestore/lite";

const PresentTable = () => {    
    const rawdata = useRouteLoaderData("couples")

    const [groups, setGroups] = useState([])
    const [couples, setCouples] = useState([])
    
    useEffect(() => {
        setCouples(rawdata.map(item => {return {...item.doc, id: item.id}}))
        getGroups().then(result => setGroups(result))
    }, [])
    

    const people = couples?.length > 0 && groups?.length > 0? couples
        .map(couple => {
            const group = groups?.find(group => refEqual(group.ref, couple.groep)) || undefined
            const groupName = group?.doc.naam || undefined
            const groupCouples = couples.filter(record => refEqual(group.ref, record.groep))
            const adults = groupCouples.map(record => record.volwassenen).flat().filter(person => person.aanwezig).length
            const children = groupCouples.map(record => record.kinderen).flat().filter(person => person.aanwezig).length

            return [
                ...couple.volwassenen?.map(volwassene => {return {...volwassene, categorie: "volwassene", koppel: couple.naam + " (" + couple.volwassenen.filter(person => person.aanwezig).length + " + " + couple.kinderen.length + ")", groep: groupName + " (" + adults + " + " + children + ")"}}), 
                ...couple.kinderen?.map(kind => {return {...kind, categorie: "kind", koppel: couple.naam + " (" + couple.volwassenen.filter(person => person.aanwezig).length + " + " + couple.kinderen.length + ")", groep: groupName + " (" + adults + " + " + children + ")"}}), 
            ]})
        .flat()
        .filter(person => person.aanwezig)
        .sort((a,b) => a.naam.localeCompare(b.naam))
        .sort((a,b) => b.categorie.localeCompare(a.categorie))
        .sort((a,b) => a.koppel?.localeCompare(b.koppel))
        .sort((a,b) => a.groep?.localeCompare(b.groep)): []

    return (  
        <div>
            <div className="container">
                <div className="row">
                    <div className="row">
                        <div style={{backgroundColor: "white", padding: 20}}>
                            <span>Volwassenen: </span>
                            <span>{people.filter(person => person.categorie === "volwassene").length}</span>
                        </div>
                        <div style={{backgroundColor: "white", padding: 20}}>
                            <span>Kinderen: </span>
                            <span>{people.filter(person => person.categorie === "kind").length}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <table className="table">   
                                <thead>
                                    <tr>
                                        <th scope="col">Groep</th>
                                        <th scope="col">Koppel</th>
                                        <th scope="col">Naam</th>
                                        <th scope="col">Categorie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {people.map(person => (
                                        <tr key={person.id}>
                                            <td>{person.groep}</td>
                                            <td>{person.koppel}</td>
                                            <td>{person.naam}</td>
                                            <td>{person.categorie}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}
 
export default PresentTable;

import './index.scss';
import { appRoutes } from './appdata/approutes';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Navbar from './components/common/navbar';
import React from 'react';


const App = () => {
  const adminEnabled = useLocation().pathname.includes('koppels')

  const visibleRoutes = appRoutes.filter(route =>
    (
      route.id !== "rsvp"
      &&
      route.id !== "couples"
    )
  )

  return (
    <React.Fragment>
      <div className='achtergrond'>
        <Outlet />
      </div>
    </React.Fragment>
  );
}

export default App;

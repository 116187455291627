import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, getDoc, updateDoc, deleteDoc, addDoc, doc } from 'firebase/firestore/lite';

const testenvironment = false

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBm1p35nR41KpMsh8l98TOqamA9hH96bIQ",
    authDomain: "huwelijkjny.firebaseapp.com",
    projectId: "huwelijkjny",
    storageBucket: "huwelijkjny.appspot.com",
    messagingSenderId: "325500584134",
    appId: "1:325500584134:web:3b4bedd44c6a1fefe8d7c7",
    measurementId: "G-HSB099KEMX"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app)

export const getCollectionDocs = async (col) => {
    if(!testenvironment){
        const snapshot = await getDocs(collection(db, col))
        return snapshot.docs.map(doc => {
            return {
                ref: doc.ref,
                id: doc.id,
                doc: doc.data()
            }
        } )
    }
    else{
        const snapshot = await apiRequest('localhost:3001/' + col)
        return snapshot
            
    }
    
}

export const getItemByRef = async (ref) => {
    const doc = await getDoc(ref)
    if(!doc.data()) throw Error("referentie van id '" + doc.id +  "' niet gevonden in de aanwezigheidslijst")
    return {
            ref: doc.ref,
            id: doc.id,
            doc: doc.data()
        }
}

export const getItemByPath = async (path) => {
    const data = await getItemByRef(doc(db, path))
    return data
}

export const deleteItemByRef = async (ref) =>{
    await deleteDoc(ref)
    return
}

export const deleteItemByPath = async (path) => {
    await deleteItemByRef(doc(db, path))
    return
}

export const deleteCoupleById = async (id) => {
    await deleteItemByPath("koppels/" + id)
    return
}

export const addItem = async (col, object) => {
    const newobject = await addDoc(collection(db, col),object)
    console.log(newobject)
    return
}

export const updateItemByRef = async (ref, newObject) => {
    const newobject = await updateDoc(ref, newObject)
    console.log(newobject)
    return
}

export const updateItemByPath = async (path, newObject) => {
    await updateItemByRef(doc(db, path), newObject)
    return
}

export const getCoupleById = async (id) => {
    if(!testenvironment){
        const data = await getItemByPath("koppels/" + id)
        return data
    }
    else{
        const data = await apiRequest("localhost:3001/koppels/" + id)
        return data
    }
    
}

export const getCoupleByParamId = async ({ params }) => {
    const { id } = params
    console.log(id)
    const data = await getCoupleById(id)
    return data

}

export const getGroupById = async (id) => {
    if(!testenvironment){
        const data = await getItemByPath("groepen/" + id)
        return data
    }
    else{
        const data = await apiRequest("localhost:3001/groepen/" + id)
        return data
    }
    
}

export const getGroups = async () => {
    const data = await getCollectionDocs('groepen')
    return data
}

export const getCouples = async () => {
    const data = await getCollectionDocs('koppels')
    return data
}

export const apiRequest = async (request) => {
    console.log('fetching data from api "' + request + '" ...')
    try {
        const res = await fetch(request)
        if (res.ok) {
            console.log('response from "' + request + '" recieved')
            return await res.json()
        }
        else throw Error('Bad Request')
    }
    catch (err) {
        console.error('Error fetching data: ', err)
    }
}
import { appRoutes } from "../../appdata/approutes";
import TitleBanner from "../common/titlebanner";
import ringPicture from "../../content/img/ring.jpg";
import React from "react";

import airplane from "../../content/img/travelwithchildren.jpg";
import beach from "../../content/img/beach.jpg";
import house from "../../content/img/jabeque.jpg";
import party from "../../content/img/quad.jpg";
import car from "../../content/img/car.jpg";
import sleep from "../../content/img/hangmat.jpg";

const Home = () => {
    const visibleRoutes = appRoutes.filter(
        (route) => route.id !== "rsvp" && route.id !== "couples"
    );

    return (
        <React.Fragment>
            <section className="fullscreen photo">
                <TitleBanner />
            </section>
            <saction className="photo">
                <div className="disclaimer">
                    <span className="title">
                        ACTIE VEREIST!
                    </span>
                    <span className="description">
                        Voor diegenen die dit nog niet gedaan hebben, kunnen jullie ons ten laatste 15 maart laten weten of jullie al dan niet aanwezig zullen zijn? <br /><br />Bedankt!                        
                    </span>
                </div>
            </saction>
            <section className="photo">
                <div className="infopage">
                    <div className="headerSection">Programma</div>
                    <div className="introSection">
                        <span>
                            Lieve gasten, <br />
                            <br />
                            Super fijn dat jullie erbij kunnen zijn! Hier is het programma
                            voor ons feestelijk weekend: <br />
                            <br />
                        </span>
                    </div>

                    <div className="infoSection">
                        <div className="event">
                            <div className="eventPictureContainer">
                                <img className="eventPicture" src={house} alt={"Kick-Off"} />
                            </div>
                            <div className="textSection">
                                <span className="title">Kick-off wedding weekend</span>
                                <span className="description">
                                    Welkom in Casa Jabeque in Bolnuevo voor een hapje en een
                                    drankje! Een informele avond om elkaar allemaal te leren
                                    kennen. <br />
                                    Geniet van het uitzicht, neem een plonsje in het zwembad en
                                    laat de festiviteiten beginnen! <br />
                                </span>
                            </div>
                            <div className="info">
                                <div className="infoVeld">
                                    <span className="label">Wanneer</span>
                                    <span className="value">
                                        Vrijdag 05/07/2024 - Van 19:00 tot 23:00
                                    </span>
                                </div>
                                <div className="infoVeld">
                                    <span className="label">Waar</span>
                                    <a className="value" target="_blank" href="https://maps.app.goo.gl/WQRmBCpoSTs8W2WQA">Travesia Jabeque 2, Bolnuevo</a>
                                </div>
                                <div className="infoVeld">
                                    <span className="label">Dress Code</span>
                                    <span className="value">
                                        Summer Holiday Vibes* <br /> 
                                        Voor diegene die een plonske willen doen, zwemgerief niet vergeten!
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="event">
                            <div className="eventPictureContainer">
                                <img className="eventPicture" src={beach} alt={"Kick-Off"} />
                            </div>
                            <div className="textSection">
                                <span className="title">‘I do’ on the beach</span>
                                <span className="description">
                                    Hello summer vibes! We verzamelen op het strand voor een korte
                                    ceremonie en heffen samen het glas. Voor de vakantiegangers
                                    onder ons – nadien chillen op het strand en afkoelen in de zee
                                    is zeker een mogelijkheid! De juiste locatie wordt een paar
                                    dagen voordien meegegeven, nog een beetje mysterie ;-) <br />
                                </span>
                            </div>
                            <div className="info">
                                <div className="infoVeld">
                                    <span className="label">Wanneer</span>
                                    <span className="value">
                                        Zaterdag 06/07/2024 - 10:00 - 11:30
                                    </span>
                                </div>
                                <div className="infoVeld">
                                    <span className="label">Waar</span>
                                    <span className="value">TBD (In de buurt van Bolnuevo)</span>
                                </div>
                                <div className="infoVeld">
                                    <span className="label">Dress Code</span>
                                    <span className="value">
                                        Summer Holiday Vibes* <br />
                                        Zonnecreme en zwemgerief niet vergeten voor de vakantiegangers
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="event">
                            <div className="eventPictureContainer">
                                <img className="eventPicture" src={party} alt={"Kick-Off"} />
                            </div>
                            <div className="textSection">
                                <span className="title">Tapas & dansen in de bergen</span>
                                <span className="description">
                                    Tijd om de zee achter ons te laten en te verhuizen naar de
                                    bergen. Geen traditionele 3-gangenmenu aan tafel, maar walking
                                    dinner met Spaanse tapas! <br />
                                    Al dansend sluiten we ons weekend af,
                                    buiten en hopelijk onder een mooie sterrenhemel.
                                </span>
                            </div>
                            <div className="info">
                                <div className="infoVeld">
                                    <span className="label">Wanneer</span>
                                    <span className="value">
                                        Zaterdag 06/07/2024 - 19:00 - 03:00
                                    </span>
                                </div>
                                <div className="infoVeld">
                                    <span className="label">Waar</span>
                                    <a className="value" target="_blank" href="https://maps.app.goo.gl/a7Qhr43dmDaV3FFJ6">
                                        La Finca Fraille in Morata
                                    </a>
                                </div>
                                <div className="infoVeld">
                                    <span className="label">Dress Code</span>
                                    <span className="value">
                                        Summer chic** <br /> 
                                        Avondfeest (inclusief dansen) is buiten
                                        te doen, met kiezeltjes als ondergrond. De bruid doet een
                                        eerste poging op hakken, maar zal al snel omschakelen naar
                                        gemakkelijkere dansschoenen.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        * Summer Holiday Vibes: Wij gaan zelf voor luchtige en gemakkelijke kledij. Formele kledij is niet nodig. <br />
                        ** Summer Chic: Wij hopen op een warme avond en een leuk dansfeestje. Een verstikkend maatpak/kleedje is daarom niet ideaal. Doe vooral iets aan waar je je goed in voelt.
                    </div>
                </div>
            </section>
            <section className="photo">
                <div className="infopage">
                    <div className="headerSection">Praktische Info</div>
                    <div className="infoSection">
                        <div className="info">
                            <img className="infoPicture" src={airplane} alt="" />
                            <div className="title">Luchthavens</div>
                            <p>
                                Er zijn twee mogelijke luchthavens in de buurt: <br /> <br />
                                Alicante <br />
                                Murcia <br />
                                <br />
                                De meeste vliegtuigmaatschappijen vliegen op Alicante, aangezien dit van de beiden de grootste luchthaven is.
                                Vanuit Alicante moet je een 1h25m rijden om in Bolnuevo aan te komen met de auto. <br />
                                <br />
                                De andere optie is de luchthaven van Murcia (Corvera). <br />
                                Het vliegaanbod van deze luchthaven is wel beperkter en volgens onze ervaring is deze iets duurder. <br />
                                Als je naar Murcia vliegt kun je niet vanuit Brussel vertrekken, wel vanuit Oostende of Antwerpen. <br />
                                Vanuit Murcia is het nog 43m rijden naar Bolnuevo. <br /> <br />
                                Ready for take-off!
                            </p>
                            <hr />
                        </div>
                        <div className="info">
                            <img className="infoPicture" src={car} alt="" />
                            <div className="title">Vervoer</div>
                            <p>
                                Geland! Dan rest nu de vraag: hoe bereik je het betoverende Bolnuevo? <br /> <br />
                                Vanuit Alicante kan je zeker de bus nemen tot in Bolnuevo (overstappen in Murcia). <br />
                                Een auto is echter geen overbodige luxe daar het tot het feest zelf ook nog een half uurtje rijden is. <br /> <br />
                                Op de luchthaven zijn verschillende verhuurbedrijven waar je een auto kan huren. Wij reserveren meestal onze auto via de site <a target="_blank" href="https://www.doyouspain.com/index.htm">doyouspain.com</a>.
                            </p>
                            <hr />
                        </div>
                        <div className="info">
                            <img className="infoPicture" src={sleep} alt="" />
                            <div className="title">Verblijf</div>
                            <p>
                                Er zijn verschillende opties ivm verblijf – alles hangt af van wat jullie juist willen. We zijn er zelf nog niet geweest, maar er zijn wel wat mogelijkheden in de buurt. <br />
                                <br />
                                <b>Hotels:</b> <br />
                                -	Hotel Playa Granda (Puerto de Mazarron / Bolnuevo): hotel aan het strand, een dik kwartiertje stappen naar ons huisje
                                <br /><a target="_blank" href="https://www.booking.com/hotel/es/playagrande.en-gb.html?aid=311984&label=playagrande-a3CLavjMLTFpPWFFatICLAS675506788040%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atikwd-30707507275%3Alp1001004%3Ali%3Adec%3Adm%3Appccp%3DUmFuZG9tSVYkc2RlIyh9YXwxhKG0pUU-mcMVT-JwQpc&sid=975115964c8a578d65133f2c6184318c&dest_id=-381096;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1701686690;srpvid=06154b8d17fd000b;type=total;ucfs=1&#hotelTmpl">Booking - Playa Grande</a> <br />
                                <br />
                                -	Hotel Atrium (Bolnuevo): hotel in de woonwijk, 5 minuten stappen tot aan het strand <br />
                                <a target="_blank" href="https://www.booking.com/hotel/es/atrium.nl.html">Booking - Hotel Atrium</a> <br />
                                <br />
                                <b>Appartementen:</b> <br />
                                -	Appartamentos Oasis de las Palmeras (Bolnuevo): vlak aan het strand – verschillende appartementen met zwembad en tennisbaan. Ook een bar en restaurant zijn aanwezig. Lijkt ons wel heel tof! <br />
                                <a target="_blank" href="https://www.booking.com/hotel/es/apartamentos-oasis-de-las-palmeras.es.html">Booking - Oasis de las Palmeras</a> <br />
                                <br />
                                <b>Huisjes:</b> <br />
                                Verschillende vakantie huizen te huur via oa <a target="_blank" href="https://www.airbnb.com/s/Bolnuevo--Spain/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-01-01&monthly_length=3&price_filter_input_type=0&channel=EXPLORE&query=Bolnuevo%2C%20Spain&date_picker_type=calendar&place_id=ChIJ3TrLbeujZA0RQhYxKxzlDf0&checkin=2024-07-05&checkout=2024-07-07&source=structured_search_input_header&search_type=user_map_move&price_filter_num_nights=2&ne_lat=37.58747951593694&ne_lng=-1.2585540809694464&sw_lat=37.530302798873144&sw_lng=-1.3161238841313718&zoom=13.870140270280315&zoom_level=13.870140270280315&search_by_map=true">Airbnb</a> – zoeken op Bolnuevo, Puerto de Mazarron of Mazarron. <br />
                                <br />
                                Laat de vakantie beginnen!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default Home;

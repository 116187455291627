import { useRouteLoaderData } from "react-router-dom";

const CalculationForm = () => {
    const rawdata = useRouteLoaderData("couples")
    const completeList = rawdata.map(item => {return {...item.doc, id: item.id}})

    const getAanwezigeKinderen = (categorie) => {
        const lijst = completeList.filter(couple => couple.categorie === categorie).map(couple => couple.kinderen.filter(person => person.aanwezig).length)
        if (lijst.length > 1) return lijst.reduce((a, b) => {return a + b})
        else return 0
    }

    const getTotaalAanwezigeKinderen = () => {
        const lijst = completeList.map(couple => couple.kinderen.filter(person => person.aanwezig).length)
        if (lijst.length > 1) return lijst.reduce((a, b) => {return a + b})
        else return 0
    }

    const getTotaalKinderen = (categorie) => {
        let lijst
        
        if (categorie)
        lijst = completeList.filter(couple => couple.categorie === categorie).map(couple => couple.kinderen.length)
        else lijst = completeList.map(couple => couple.kinderen.length)

        if (lijst.length > 1) return lijst.reduce((a, b) => {return a + b})
        else return 0
    }

    const getAanwezigeVolwassenen = (categorie) => {
        const lijst = completeList.filter(couple => couple.categorie === categorie).map(couple => couple.volwassenen.filter(person => person.aanwezig).length)
        if (lijst.length > 1) return lijst.reduce((a, b) => {return a + b})
        else return 0
    }

    const getTotaalAanwezigeVolwassenen = () => {
        const lijst = completeList.map(couple => couple.volwassenen.filter(person => person.aanwezig).length)
        if (lijst.length > 1) return lijst.reduce((a, b) => {return a + b})
        else return 0
    }

    const getTotaalVolwassenen = (categorie) => {
        let lijst

        if (categorie)
        lijst = completeList.filter(couple => couple.categorie === categorie).map(couple => couple.volwassenen.length)
        else lijst = completeList.map(couple => couple.volwassenen.length)

        if (lijst.length > 1) return lijst.reduce((a, b) => {return a + b})
        else return 0
    }


    return ( 
        <table className="table">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Volwassenen</th>
                    <th scope="col">Kinderen</th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>Must haves</td>
                <td>{getAanwezigeVolwassenen("Must have")}/{getTotaalVolwassenen("Must have")}</td>
                <td>{getAanwezigeKinderen("Must have")}/{getTotaalKinderen("Must have")}</td>
            </tr>
            <tr>
                <td>Nice to haves</td>
                <td>{getAanwezigeVolwassenen("Nice to have")}/{getTotaalVolwassenen("Nice to have")}</td>
                <td>{getAanwezigeKinderen("Nice to have")}/{getTotaalKinderen("Nice to have")}</td>
            </tr>
            <tr>
                <td>Obligated</td>
                <td>{getAanwezigeVolwassenen("Obligated")}/{getTotaalVolwassenen("Obligated")}</td>
                <td>{getAanwezigeKinderen("Obligated")}/{getTotaalKinderen("Obligated")}</td>
            </tr> 
            <tr>
                <th>Totaal</th>
                <th>{
                    getTotaalAanwezigeVolwassenen()
                }/{
                    getTotaalVolwassenen()
                }</th>
                <th>{
                    getTotaalAanwezigeKinderen()
                }/{
                    getTotaalKinderen()
                }</th>
            </tr>                 
            </tbody>
        </table>
     );
}
 
export default CalculationForm;
import React from "react";

const TitleBanner = () => {
    return ( 
        <React.Fragment>
            <div className="titlebanner">
                <p className=" statement">Wij gaan trouwen</p>
                <span className="namen">Joyce & Yelle</span>
                <span className="datum">06 Juli 2024</span>
            </div>
            <div className='scrolldown'>
                <i className="bi bi-arrow-down me-3"></i>
                Scroll Down
            </div>
        </React.Fragment>
     );
}
 
export default TitleBanner;
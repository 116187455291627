import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { deleteCoupleById, getGroups } from "../../../services/apiservice";
import { useEffect, useState } from "react";
import ListGroup from "../../common/listGroup";
import { refEqual } from "firebase/firestore/lite";

const CoupleTable = () => {
    const navigate = useNavigate()
    const rawdata = useRouteLoaderData("couples")
    const completeList = rawdata.map(item => {return {...item.doc, id: item.id}})
    
    const [selectedCategory, selectCategory] = useState(null)
    const [selectedStatus, selectStatus] = useState(null)
    const [selectedGroup, selectGroup] = useState(null)
    const [searchQuery, setSearchQuery] = useState(null)
    const [groups, setGroups] = useState(null)
    const [list, setList] = useState(completeList)
    
    useEffect(() => {
        getGroups().then(groups => setGroups(groups))
    }, [])
    
    useEffect(() => {
        const listFilter = completeList.filter(item => {return (
            (selectedCategory? item.categorie === selectedCategory: true) &&
            (selectedStatus? 
                selectedStatus === "Niet gestuurd"? !item.gestuurd: 
                selectedStatus === "Niet bevestigd"? item.confirmaties.savethedate === false: 
                selectedStatus === "Aanwezig"? item.confirmaties.savethedate === true && item.aanwezig: 
                item.confirmaties.savethedate === true && !item.aanwezig: 
            true) &&
            (selectedGroup? 
                refEqual(selectedGroup.ref, item.groep): 
            true) &&
            (searchQuery? (
                item.naam.toLowerCase().includes(searchQuery) || 
                item.volwassenen.filter(person => person.naam.toLowerCase().includes(searchQuery)).length > 0 ||
                item.kinderen.filter(person => person.naam.toLowerCase().includes(searchQuery)).length > 0
            ):true)
                )})

        setList(listFilter)
    }, [selectedCategory, selectedStatus, selectedGroup, searchQuery])

    const getDetails = (id) => {
        return navigate("/koppels/" + id)
    }

    const deleteCouple = async (id) => {
        await deleteCoupleById(id)
        const newlist = list.filter(item => item.id != id)
        setList(newlist)
        return
    }

    const clearFilter = () => {
        selectCategory(null)
        selectStatus(null)
        selectGroup(null)
        setSearchQuery(null)
    }

    return (  
        <div>
            <div className="commandbar">
                <div className="buttonContainer">
                    <button onClick={() => navigate("/koppels/add")} className="btn btn-primary m-3">Nieuw</button>
                    <button className="btn btn-primary m-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Filter</button>
                </div>
                <input className="form-control m-3 searchbar" type="search" placeholder="Search" onChange={(e) => setSearchQuery(e.target.value.toLowerCase())} aria-label="Search"></input>
            </div>

            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasScrollingLabel">Filter</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                {(selectedCategory || selectedStatus || selectedGroup || searchQuery)? <button className="btn btn-danger" onClick={clearFilter}>Clear filter</button>:null}
                <div className="offcanvas-body">
                <div className="col mb-5">   
                        <label className="form-label">Categorie</label>
                        <ListGroup 
                            items={[{value: "Must have"}, {value: "Nice to have"}, {value: "Obligated"}]} 
                            textProperty={"value"} 
                            primaryKeyProperty={"value"} 
                            selectedItem={{value: selectedCategory}} 
                            onItemSelect={(item) => {item.value === selectedCategory? selectCategory(null): selectCategory(item.value)}}/>
                    </div>
                    <div className="col mb-5">   
                        <label className="form-label">Status</label>
                        <ListGroup 
                            items={[{value: "Niet gestuurd"}, {value: "Niet bevestigd"}, {value: "Aanwezig"}, {value: "Niet Aanwezig"}]} 
                            textProperty={"value"} 
                            primaryKeyProperty={"value"} 
                            selectedItem={{value: selectedStatus}} 
                            onItemSelect={(item) => {item.value === selectedStatus? selectStatus(null): selectStatus(item.value)}}/>
                    </div>
                    <div className="col mb-5">   
                        <label className="form-label">Groep</label>
                        <ListGroup 
                            items={groups? groups.map(group => {return {value: group.doc.naam}}): []} 
                            textProperty={"value"} 
                            primaryKeyProperty={"value"} 
                            selectedItem={selectedGroup? {value: selectedGroup.doc.naam}: null} 
                            onItemSelect={(item) => {(item.value === (selectedGroup? selectedGroup.doc.naam: ""))? selectGroup(null): selectGroup(groups? groups.find(group => group.doc.naam === item.value): null)}}/>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    
                    <div className="row">
                        <div className="col">
                            <table className="table">   
                                <thead>
                                    <tr>
                                        <th scope="col">Groep</th>
                                        <th scope="col">Koppel</th>
                                        <th scope="col">Link</th>
                                        <th scope="col">Status</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map(item => (
                                        <tr key={item.id}>
                                            <td>{groups? groups.find(group => refEqual(group.ref, item.groep)).doc.naam: null}</td>
                                            <td>{item.naam}</td>
                                            <td>{"www.jnyforever.be/savethedate/" + item.id}</td>
                                            <td>{item.gestuurd? item.confirmaties.savethedate? item.aanwezig? "Aanwezig":"Niet Aanwezig": "Niet bevestigd":"Niet gestuurd"}</td>
                                            <td><button onClick={() => getDetails(item.id)} className="btn btn-primary">Details</button></td>
                                            <td><button onClick={() => deleteCouple(item.id)} className="btn btn-danger">Delete</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}
 
export default CoupleTable;
import { redirect } from "react-router-dom";
import { getCoupleById } from "../../../services/apiservice";
import { updateDoc } from "firebase/firestore/lite";
import CoupleForm from "../../common/couplefrm";

const AddCouple = () => {
    const template =  {
        id: null,
        naam: "",
        aanwezig: false,
        accepteerkinderen: false,
        categorie: "Nice to have",
        gestuurd: false,
        confirmaties: {
            savethedate: false,
            events: false
        },
        email: "",
        tel: "",
        groep: null,
        opmerkingen: "",
        volwassenen: [],
        kinderen: []
    }

    return (
        <div>
            <CoupleForm coupleItem={template} newCouple/>
        </div>
    )
}
 
export default AddCouple;